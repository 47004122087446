import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../styles/error.styles.sass'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div>
      <div id="main">
        <div class="fof">
          <h1>Error 404</h1>
          <br/>
          <Link to="/"> Go to Home </Link>
        </div>
      </div>
    
    </div>
  </Layout>
)

export default NotFoundPage
